import React, { useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import { utils, read } from "xlsx";
import * as Yup from "yup";
import { motion, useCycle } from "framer-motion";
import { useMutation } from "@apollo/client";
import { useSelector } from 'react-redux';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { CloseOutlined } from "@material-ui/icons";

import PageComponent from "../../shared/components/page/page.component";
import CustomLoadingOverlay from '../../shared/components/misc/loading_overlay.component';

import { BUY_AIRTIME, BUY_BULK_AIRTIME } from "../../shared/services/mutations/airtime";
import { toUpper } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  box: {
    margin: "10px",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  layout: {
    width: "auto",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 650,
      marginTop: theme.spacing(3),
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  table: {
    backgroundColor: 'white',
    maxHeight: 400,
    overflowX: 'auto'
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const networks = [
  {
    id: 1,
    name: "mtn",
  },
  {
    id: 2,
    name: "9mobile",
  },
  {
    id: 3,
    name: "airtel",
  },
  {
    id: 4,
    name: "glo",
  },
];

export default function BuyAirtimeComponent() {
  const classes = useStyles();

  const [isOpen] = useCycle(true, false);

  const [buyType, setBuyType] = React.useState("single");

  const variants = {
    open: (height = 1000) => ({
      clipPath: `circle(200% at 100% 100%)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(10% at 100% 100%)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <PageComponent className={classes.root} title="Buy Airtime | Admin">
      <motion.div animate={isOpen ? "open" : "closed"} variants={variants}>
        <React.Fragment>
          <main className={classes.layout}>
            {/* Select Buy Type */}
            <Grid item md={12} className={classes.paper}>
              <FormControl fullWidth variant="outlined">
                <Paper elevation={4} />
                <InputLabel>Select Type</InputLabel>
                <Select
                  label="Select Type"
                  onChange={(event) => setBuyType(event.target.value)}
                  value={buyType}
                >
                  {["single", "bulk"].map((name) => (
                    <MenuItem key={name} value={name}>
                      {toUpper(name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Container maxWidth={false}>
              {buyType === "single" ? (
                <BuySingleAirtimeComponent />
              ) : (
                <BuyBulkAirtimeComponent />
              )}
            </Container>
          </main>
        </React.Fragment>
      </motion.div>
    </PageComponent>
  );
}

const BuySingleAirtimeComponent = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [buyAirtime] = useMutation(BUY_AIRTIME, {
    fetchPolicy: "no-cache",
  });

  return (
    <>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          onClose={(event, reason) =>
            reason === "clickaway" ? null : setOpen(false)
          }
          message={snackbarMessage}
          action={
            <IconButton onClick={() => setOpen(false)}>
              <CloseOutlined style={{ color: "white" }} />
            </IconButton>
          }
        />
      </Portal>
      <Grid item md={12}>
        <Paper elevation={4} />
        <Formik
          initialValues={{
            phonenumber: "",
            network: "mtn",
            amount: 0,
          }}
          validationSchema={Yup.object().shape({
            phonenumber: Yup.string()
              .required("Phonenumber is required")
              .min(
                11,
                "Please ensure your phonenumber is eleven characters and starts with a zero"
              )
              .max(
                11,
                "Please ensure your phonenumber is eleven characters and starts with a zero"
              )
              // eslint-disable-next-line func-names
              .test("isValid", "Invalid phonenumber", function (value) {
                if (value === undefined) return false;
                return /(^0)(7|8|9){1}(0|1){1}[0-9]{8}/.test(
                  value.trim().replace(/\s/g, "")
                );
              }),
            network: Yup.string().required("Network is required"),
            amount: Yup.number()
              .required("Minimum amount of N100")
              .min(100, "Minimum amount of N100") // Revert back to 500
              .max(500000, "Maximum amount of N100000 exceeded"),
          })}
          onSubmit={async (values, { resetForm }) => {
            let data;
            try {
              data = await buyAirtime({
                variables: {
                  phonenumber: values.phonenumber,
                  network: values.network,
                  amount: values.amount,
                },
              });
            } catch (e) {
              setSnackbarMessage("Something went wrong");
              setOpen(true);
              return;
            }

            if (!data.errors) {
              if (data.data?.buyAirtime?.success) {
                resetForm();
              }

              setSnackbarMessage(data.data?.buyAirtime?.message);
              setOpen(true);
              return;
            }

            setSnackbarMessage(data.errors[0]?.originalError?.message);
            setOpen(true);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Paper elevation={4} className={classes.paper}>
                <Box
                  className={classes.box}
                  display={"flex"}
                  justifyContent={"center"}
                  width={"100%"}
                  paddingBottom={"20px"}
                >
                  <Typography component="h1" variant="h3" align="center">
                    Buy Airtime
                  </Typography>
                </Box>
                <>
                  <TextField
                    id="phonenumber"
                    variant="outlined"
                    margin="normal"
                    label="Phonenumber"
                    required
                    type="text"
                    fullWidth
                    name="phonenumber"
                    placeholder="Enter the recipient's phone number"
                    // sx={style.textField}
                    value={values.phonenumber.trim().replace(/\s/g, "")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.phonenumber && errors.phonenumber)}
                    helperText={touched.phonenumber && errors.phonenumber}
                    autoComplete="phonenumber"
                  />
                </>
                <Box padding="15px" />
                <>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Network</InputLabel>
                    <Select
                      labelId="network"
                      id="network"
                      name="network"
                      label="Network"
                      onChange={handleChange}
                      value={values.network}
                    >
                      {networks.map(({ id, name }) => (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
                <Box padding="15px" />
                <>
                  <TextField
                    id="amount"
                    name="amount"
                    label="Amount"
                    type={"number"}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          &#8358;
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </>
                <Box padding="15px" />
                <Box display="flex">
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={isSubmitting}
                    type={"submit"}
                  >
                    {isSubmitting ? <CircularProgress /> : "Buy"}
                  </Button>
                </Box>
              </Paper>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

const BuyBulkAirtimeComponent = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [file, setFile] = React.useState(null);
  const [array, setArray] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const phoneUtil = PhoneNumberUtil.getInstance();

  const customersState = useSelector((state) => state.customersState);

  const [buyBulkAirtime] = useMutation(BUY_BULK_AIRTIME, {
    fetchPolicy: "no-cache",
  });

  const parseArray = (array) => {
    return array
      .filter((t) => t["Mobile Number"].toString().trim() !== "Total")
      .map((t) => {
        let phonenumber = t["Mobile Number"].toString().replace(/[^0-9]/g, "");
        const parsed = `${phoneUtil.format(
          phoneUtil.parse(phonenumber, "NG"),
          PhoneNumberFormat.NATIONAL
        )}`
          .replace(/\s/g, "")
          .slice(-10);
        return {
          phonenumber: `0${phoneUtil.parse(parsed, "NG").getNationalNumber()}`,
          network: t['network'],
          amount: t["Amount"],
          name: t['Beneficiray name'] || t['Name'] || t['Beneficiary name']
        };
      });
  };
  
  const addNetwork = (array, sheetName) => {
    return array.map((a) => ({ ...a, network: _.toLower(sheetName) }));
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);

    let data;
    try {
      data = await buyBulkAirtime({
        variables: {
          userId: user.id,
          phonenumbers: array[page]
            .map((a) => ({
              phonenumber: a.phonenumber,
              network: a.network,
              amount: a.amount,
              name: a.name,
            }))
        },
      });
    } catch (e) {
      setIsSubmitting(false);
      setSnackbarMessage("Something went wrong");
      setOpen(true);
      return;
    }

    setIsSubmitting(false);

    if (!data.errors) {
      if(page + 1 < array.length) {
        setPage(page + 1);
      }

      setSnackbarMessage(data.data?.buyBulkAirtime?.message);
      setOpen(true);
      return;
    }

    setSnackbarMessage(data.errors[0]?.originalError?.message);
    setOpen(true);
  };
  
  const loadFile = (files) => {
    setFile(files?.item(0));
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      let phonenumbers = [];

      for(const element of workbook.SheetNames) {
        const sheetName = element;
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        if(
          ['mtn', '9mobile', 'airtel', 'glo']
            .includes(_.toLower(sheetName))
        ) {
          const parsedArray = parseArray(addNetwork(json, sheetName));
          phonenumbers = [...phonenumbers, ...parsedArray];
        }
      }

      const perChunk = 50 // items per chunk    

      const result = phonenumbers
        .map((a, index) => ({ id: index + 1, ...a }))
        .reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index/perChunk)

          if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
          }

          resultArray[chunkIndex].push(item)

          return resultArray
        }, [])

      setArray(result);
    };
    reader.readAsArrayBuffer(files.item(0));
  }

  return (
    <>
        <Portal>
            <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            onClose={(event, reason) =>
                reason === "clickaway" ? null : setOpen(false)
            }
            message={snackbarMessage}
            action={
                <IconButton onClick={() => setOpen(false)}>
                <CloseOutlined style={{ color: "white" }} />
                </IconButton>
            }
            />
        </Portal>
        <Paper elevation={4} className={classes.paper}>
          <Box
              className={classes.box}
              display={"flex"}
              justifyContent={"center"}
              width={"100%"}
              paddingBottom={"20px"}
          >
              <Typography component="h1" variant="h3" align="center">
              Buy Bulk Airtime
              </Typography>
          </Box>
          <>
              {customersState.isLoading ? (
              <CustomLoadingOverlay />
              ) : (
              <>
                <Autocomplete
                  id="user"
                  options={customersState.customers}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.phonenumber ?? ""}
                  renderOption={(option) => (
                    <React.Fragment>
                      <ListItemText
                        primary={option.phonenumber}
                        secondary={
                          <span>{`${option.firstname} ${option.lastname}`}</span>
                        }
                      />
                    </React.Fragment>
                  )}
                  onChange={(event, value) => {
                    return value !== null ? setUser(value) : null;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="user"
                      type="text"
                      label="User"
                      // error={Boolean(touched.meternumber && errors.meternumber)}
                      // helperText={touched.meternumber && errors.meternumber}
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event, value) => {
                        console.log(`${event.target.value}`, value);
                        // setUser(event.target.value);
                      }}
                      inputProps={{
                          ...params.inputProps, // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <Box padding="15px" />
                <Box display={'flex'} justifyContent={'space-between'}>
                  <label>
                    <input
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      type="file"
                      hidden
                      onChange={({ target: { files } }) => {
                        loadFile(files);
                      }}
                    />
                    <span style={{ marginRight: "5px" }}>
                        {file === null ? <i>No file uploaded</i> : file.name}
                    </span>
                    <span className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary">
                      Select file
                    </span>
                  </label>
                  {
                    array.length > 0 && <FormControl variant="outlined">
                      <InputLabel>Page</InputLabel>
                      <Select
                        id="page"
                        name="page"
                        label="Page"
                        onChange={(event) => setPage(event.target.value)}
                        value={page}
                      > 
                        {new Array(array.length).fill().map((number, index) => (
                          <MenuItem key={index} value={index}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  } 
                </Box>
                <Box padding="15px" />
                {
                  array.length > 0 && <DisplayTable rows={array[page]} />
                }
                <Box padding="15px" />
                <Box display="flex">
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={isSubmitting || user === null}
                    onClick={() => handleSubmit()}
                  >
                    {isSubmitting ? <CircularProgress /> : "Buy"}
                  </Button>
                </Box>
              </>
              )}
          </>
        </Paper>
    </>
  );
};

const DisplayTable = ({ rows }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table}>
      <Table aria-label="transactions table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phonenumber</TableCell>
            <TableCell>Network</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.phonenumber}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.network}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.amount}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
